import React, { Suspense } from 'react';
import { getApiPrayerTime } from '../../../../../services/selectors/prayersSelectors';
import TextFadeComponent from '../../../../../layouts/TitleComponent';
import GlassedBox from '../../../../../layouts/glassmorphism/boxGlassed';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const SkeletonLoader = React.lazy( () => import( '../../../../../layouts/skeleton' ) );

const Midnight = () => {
    const { t } = useTranslation();
    const apiPrayerTime = useSelector( getApiPrayerTime );
    const glassedBoxStyles = {
        width: '33.33%',
        borderRadius: '2vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '2.5vh',
        marginLeft: '2.5vh',
        overflow: 'hidden',
    };
    const typographyStyles = {
        fontSize: {
            xs: '3.4vh',
            sm: '3.4vh',
            md: '3.4vh',
        },
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const spanStyles = {
        margin: '2vh',
        fontSize: {
            xs: '4.5vh',
            sm: '4.5vh',
            md: '4.5vh',
        },
    };
    return (
        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles }>
            <Box sx={ typographyStyles }>
                <TextFadeComponent text={ t( 'salat.midnight' ) } textStyle={ typographyStyles } />
                <Typography sx={ spanStyles }>
                    { apiPrayerTime?.Midnight }
                </Typography>
            </Box>
        </GlassedBox>
    );
};

export default Midnight;
