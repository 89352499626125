import { createSelector } from 'reselect';

// Base selector that gets the appStatus slice from the state
const getAppStatus = state => state.appStatus;

// Memoized selectors for each piece of data in the appStatus
export const getIsAdhan = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isAdhan
);

export const getIsIqama = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isIqama
);

export const getIsShuruq = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isShuruq
);

export const getIsDarkMode = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isDarkMode
);

export const getIsThirdNight = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isThirdNight
);

export const getIsMiddleNight = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.isMiddleNight
);

export const getFocusMode = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.focusMode
);

export const getDarkMode = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.darkMode
);

export const getAdhkarSalat = createSelector(
    [ getAppStatus ],
    appStatus => appStatus.adhkarSalat
);


