import React from 'react';
import Box from '@mui/material/Box';
import logo from '../../styles/img/logos.webp';
const SplashScreen = () => {

    return (
        <Box
            className='gm-dmode'
            sx={ {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#FABF0F',
                overflow: 'hidden',
                borderRadius: '1px'
            }
            }
        >
            <img
                src={ logo }
                alt="Loading..."
                loading="lazy"
                sizes="(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px"
                style={ {
                    maxWidth: '20vw',
                    maxHeight: '20vw',
                    objectFit: 'contain',
                } }
            />
        </Box >
    );
};

export default SplashScreen;
