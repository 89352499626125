import React from 'react';
import { Box, Typography } from '@mui/material';
import { containsArabic } from '../../../services/Lang';

const TextDisplay = ( { text } ) => {

    const isArabic = containsArabic( text || '' );

    let fontSize;

    if ( text?.length <= 100 ) {
        fontSize = '5.5vw';
    } else if ( text?.length <= 200 ) {
        fontSize = '4vw';
    } else {
        fontSize = '3.6vw';
    }

    return (
        <Box
            sx={ {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                p: 1
            } }>
            <Typography
                className={ `${ isArabic ? 'arrkgt4' : '' }` }
                sx={ {
                    textAlign: "center",
                    m: 1,
                    fontSize: fontSize
                } }>
                { text?.slice( 0, 280 ) }{ text?.length > 280 ? " ..." : " " }
            </Typography>
        </Box>
    );
};

export default React.memo( TextDisplay );
