import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import ar from './services/translation/ar.json';
import ur from './services/translation/ur.json';
import bn from './services/translation/bn.json';
import en from './services/translation/en.json';
import fr from './services/translation/fr.json';
import ja from './services/translation/ja.json';

const Languages = [ 'en', 'fr', 'ar', 'ur', 'bn', 'ja' ];

i18n
    .use( initReactI18next )
    .init( {
        fallbackLng: 'en',
        lng: localStorage.getItem( "lang" ) || "en",
        preload: [ "ar", "en" ],
        initImmediate: false,
        debug: true,
        resources: {
            ar: { translation: ar },
            ur: { translation: ur },
            bn: { translation: bn },
            en: { translation: en },
            fr: { translation: fr },
            ja: { translation: ja },
        },
        supportedLngs: Languages,

        interpolation: {
            escapeValue: false,
        }
    } );


export default i18n;