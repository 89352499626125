import React, { Suspense } from 'react';
import NotificationLayout from '../../views/app/features/notifications';
import PrayerLandscape from '../../views/pages/prayersLandscape';
import ApplicationSettings from '../../views/wrappers/settings';
import AfterSalatActions from '../../views/wrappers/aftersalat';
import PrayerGetterAndLoading from '../../views/wrappers/salat';
import UseSocketIoSetup from '../../views/wrappers/useSocket';
import StateHandlerWrapper from '../../views/wrappers/state';
import { useOrientation } from '../../hooks/useOrientation';

const PrayerPortrait = React.lazy( () => import( '../../views/pages/prayersPortrait' ) );
const Menu = React.lazy( () => import( '../../views/wrappers/menu' ) );
const DinScreen = () => {
    const { orientation, acceptPortrait } = useOrientation();
    const { type } = orientation;
    const isLandscape = type?.startsWith( 'landscape' );
    return (
        <PrayerGetterAndLoading>
            <UseSocketIoSetup>
                <ApplicationSettings isLandscape={ isLandscape }>
                    <AfterSalatActions>
                        <NotificationLayout>
                            <StateHandlerWrapper>
                                { isLandscape ? (
                                    <>
                                        <PrayerLandscape />
                                        <Suspense fallback={ <></> }>
                                            <Menu />
                                        </Suspense>
                                    </>
                                ) : acceptPortrait ? (
                                    <Suspense fallback={ <></> }>
                                        <PrayerPortrait />
                                    </Suspense>
                                ) : (
                                    <>
                                        <PrayerLandscape />
                                        <Suspense fallback={ <></> }>
                                            <Menu />
                                        </Suspense>
                                    </>
                                ) }
                            </StateHandlerWrapper>
                        </NotificationLayout>
                    </AfterSalatActions>
                </ApplicationSettings>
            </UseSocketIoSetup>
        </PrayerGetterAndLoading >
    );
};

export default React.memo( DinScreen );  
