// utils.js
import axios from 'axios';
import UAParser from 'ua-parser-js';

function getCodeFromLocalStorageOrUrl ( codeFromUrl ) {
    try {
        // Try to retrieve the code from localStorage
        const storedCode = localStorage.getItem( 'code' );
        if ( storedCode ) {
            return storedCode;
        }

        // If no code in localStorage, use the code from the URL and store it in localStorage
        if ( codeFromUrl ) {
            localStorage.setItem( 'code', codeFromUrl );
            return codeFromUrl;
        }
    } catch ( err ) {
        console.error( "Error accessing or setting localStorage:", err );
    }

    // If neither localStorage nor URL has the code, return null
    return null;
}


export async function getClientInfo ( codeFromUrl ) {
    // Get the code from localStorage or URL
    const code = getCodeFromLocalStorageOrUrl( codeFromUrl );

    // Try to get cached data from localStorage
    const cachedData = localStorage.getItem( '945durqxu' );
    if ( cachedData ) {
        return JSON.parse( cachedData );
    }

    // Initialize UAParser
    const parser = new UAParser();
    const result = parser.getResult();

    // Get client IP using an external service
    const ipResponse = await fetch( 'https://api.ipify.org?format=json' );
    const ipResult = await ipResponse.json();

    // Prepare the client information to be cached
    const clientInfo = {
        code: code,
        userAgent: result,
        ip: ipResult.ip,
    };

    // Cache the client information in localStorage
    localStorage.setItem( '945durqxu', JSON.stringify( clientInfo ) );

    return clientInfo;
}




export const getPrayerTiming = async ( phoneNum, code ) => {
    try {

        const clientInfo = await getClientInfo( code );

        const res = await axios.post( `/tapy/masjid/tv/exzsw/${ phoneNum }`, {
            clientDetails: clientInfo,
        } );
        if ( !res.data.data ) {
            throw new Error( 'No data received from server' );
        }
        return res.data;
    } catch ( error ) {
        return false;
    }

};
export const postPrayerTiming = async ( extId, code ) => {
    try {

        const clientInfo = await getClientInfo( code );

        const res = await axios.post( `/tapy/masjid/tv/salat/${ extId }`, {
            clientDetails: clientInfo,
        } );
        if ( !res.data.data ) {
            throw new Error( 'No data received from server' );
        }
        return res.data;
    } catch ( error ) {
        return false;
    }

};






