import { io } from 'socket.io-client';

let socket = null;

export async function initializeSocket ( token ) {
    const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:5001';
    socket = io( URL, {
        auth: {
            token,
        },
        withCredentials: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
    } );

    return socket;
}

export function getSocket () {
    if ( !socket ) {
        // console.log( "socket not initialized" )
    }
    return socket;
}
