import '../tltxa.min.css';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import usePrayerTimeState from '../../../../../../hooks/prayerState';
import TextFadeComponent from '../../../../../../layouts/TitleComponent';
import { convertTimeWorld } from '../../../../../../services/formatDate';
import GlassedBox from '../../../../../../layouts/glassmorphism/boxGlassed';
import {
    getIsIqama,
    getIqamaTime,
    getTvSettings,
    getHighlighted,
    getApiPrayerTime,
    getTomorrowIqamaTime,
    getTomorrowApiPrayerTime,
} from '../../../../../../services/selectors/prayersSelectors';

const SalatAdhanBox = () => {

    const theme = useTheme();
    const { t } = useTranslation();
    const iqamaTime = useSelector( getIqamaTime );
    const tomorrowApiPrayerTime = useSelector( getTomorrowApiPrayerTime );
    const tomorrowIqamaTime = useSelector( getTomorrowIqamaTime );
    const apiPrayerTime = useSelector( getApiPrayerTime );
    const highlighted = useSelector( getHighlighted );
    const isIqama = useSelector( getIsIqama );
    const tvSettings = useSelector( getTvSettings );
    const { f, d, a, m, i } = highlighted;
    const state = usePrayerTimeState( iqamaTime, tomorrowIqamaTime );

    const dataAvailable = tomorrowApiPrayerTime && apiPrayerTime;

    const renderPrayerTime = useCallback(
        ( time, diff, nextTime ) =>
            dataAvailable ? (
                diff ? convertTimeWorld( tvSettings?.isTimeConvention, nextTime ) : convertTimeWorld( tvSettings?.isTimeConvention, time )
            ) : (
                null
            ),
        [ dataAvailable, tvSettings ]
    );

    const getPrayerTimes = useCallback( () => [
        { key: 'Isha', state: state.Isha, diff: ( state.iDiff && isIqama && i ), time: apiPrayerTime?.Isha, nextTime: tomorrowApiPrayerTime?.Isha },
        { key: 'Maghrib', state: state.Maghrib, diff: ( state.mDiff && isIqama && m ), time: apiPrayerTime?.Maghrib, nextTime: tomorrowApiPrayerTime?.Maghrib },
        { key: 'Asr', state: state.Asr, diff: ( state.aDiff && isIqama && a ), time: apiPrayerTime?.Asr, nextTime: tomorrowApiPrayerTime?.Asr },
        { key: 'Dhuhr', state: state.Dhuhr, diff: ( state.dDiff && isIqama && d ), time: apiPrayerTime?.Dhuhr, nextTime: tomorrowApiPrayerTime?.Dhuhr },
        { key: 'Fajr', state: state.Fajr, diff: ( state.fDiff && isIqama && f ), time: apiPrayerTime?.Fajr, nextTime: tomorrowApiPrayerTime?.Fajr },
    ], [ state, apiPrayerTime, tomorrowApiPrayerTime, i, m, a, d, f ] );

    const prayerTimes = getPrayerTimes();

    const boxStyles = {
        height: isIqama ? '40%' : '65%',
        display: 'flex',
        borderRadius: '2vh',
    };

    const glassedBoxStyles = ( isActive, diff ) => ( {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        backgroundColor: isActive ? '#fff' : '',
        ...( isActive ? { gmode: 'gm-xmode321s', marginTop: '0.5vh' } : ( diff ? { gmode: 'gm-lmod5431s' } : {} ) ),
    } );

    const typographyStyles = {
        fontSize: theme.palette.salatFont,
    };

    return (
        <Box sx={ boxStyles }>
            { prayerTimes?.map( ( prayer ) => (
                <GlassedBox
                    key={ prayer.key }
                    gmode={ glassedBoxStyles( prayer.state, prayer.diff ).gmode }
                    styles={ glassedBoxStyles( prayer.state, prayer.diff ) }
                >
                    <Typography className="tltxa" sx={ typographyStyles }>
                        { renderPrayerTime( prayer.time, prayer.diff, prayer.nextTime ) }
                    </Typography>
                </GlassedBox>
            ) ) }
            <GlassedBox styles={ { flex: 1 } }>
                <TextFadeComponent text={ t( 'salat.adhan' ) } textStyle={ { fontSize: '3.5vw', marginTop: "2vh" } } />
            </GlassedBox>
        </Box>
    );
};

export default React.memo( SalatAdhanBox );
