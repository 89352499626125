import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SocketContext } from '../../context/socket';
import useNavigatorOnLine from '../../hooks/onlineStatus';
import logo from '../../styles/img/logo.webp';

const styles = {
    logo: {
        marginLeft: '1.5vh',
        marginRight: '1.4vh',
        marginTop: '0.3vh',
        maxWidth: '3.5vw',
        maxHeight: '1.2vw',
        objectFit: 'contain',
        borderRadius: '50%',
    },
    typography: ( white ) => ( {
        fontSize: {
            xs: "1.8vh",
            sm: "1.8vh",
            md: "1.8vh",
        },
        color: white ? "#fff" : "none",
    } ),
    socketIconButton: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: {
            sm: '-0.5vw',
            xs: '-1.5vw',
            sm: '-1.2vw',
            md: '-0.8vw',
            lg: '-0.8vw',
            xl: '-0.5vw',
        }
    },
    boltIcon: ( isConnected ) => ( {
        color: isConnected ? '#FABF0F' : '#fff',
        textShadow: '0 0 2px #000000, 0 0 2px #000000',
        fontSize: '3vh',
    } ),
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0.5vw',
    },
    centerBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightTypography: ( white ) => ( {
        marginRight: '2vh',
        fontSize: {
            xs: "1.8vh",
            sm: "1.8vh",
            md: "1.8vh",
        },
        color: white ? "#fff" : "none",
    } ),
    offlineIcon: {
        color: 'red',
        textShadow: '0 0 2px #000000, 0 0 2px #000000',
        fontSize: '2vh',
    }
};

const Footer = ( { isLandscape = false, screenName = 'DinScreen', dinscreen = true, white = false } ) => {
    const { isConnected } = useContext( SocketContext );
    const code = localStorage.getItem( 'code' );
    const online = useNavigatorOnLine();
    const todayVersion = "• 2024 September 25 version.";

    return (
        <Box sx={ styles.footerContainer }>
            <Box sx={ { display: 'flex' } }>
                <img
                    src={ logo }
                    srcSet={ `${ logo } 400w, ${ logo } 800w, ${ logo } 1200w` }
                    sizes="(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px"
                    alt="Logo"
                    loading="lazy"
                    style={ styles.logo }
                />
                <Typography sx={ styles.typography( white ) }>
                    Moonode.com { code ? '• ' : "" }{ code ?? '' }
                </Typography>
            </Box>

            <Box sx={ styles.centerBox }>
                <Tooltip title={ isConnected ? "Insta Change On" : "Insta Change Off" }>
                    <>
                        { dinscreen ? (
                            <IconButton sx={ styles.socketIconButton }>
                                <i className="material-icons" style={ styles.boltIcon( isConnected ) }>bolt</i>
                            </IconButton>
                        ) : <span></span> }
                    </>
                </Tooltip>
            </Box>

            <Typography sx={ styles.rightTypography( white ) }>
                © { screenName } { isLandscape ? todayVersion : "" }
                { !online && (
                    <Tooltip title="Tv Offline">
                        <>
                            <i className="material-icons" style={ styles.offlineIcon }>wifi_off</i>
                        </>
                    </Tooltip>
                ) }
            </Typography>
        </Box>
    );
};

export default React.memo( Footer );
