import { createSelector } from 'reselect';

// Base selector that gets the prayers slice from the state
const getPrayersState = state => state.prayers;

// Memoized selectors for each piece of data
export const getIqamaTime = createSelector(
    [ getPrayersState ],
    prayers => prayers.iqamaTime
);

export const getTomorrowApiPrayerTime = createSelector(
    [ getPrayersState ],
    prayers => prayers.tomorrowApiPrayerTime
);

export const getTomorrowIqamaTime = createSelector(
    [ getPrayersState ],
    prayers => prayers.tomorrowIqamaTime
);

export const getApiPrayerTime = createSelector(
    [ getPrayersState ],
    prayers => prayers.apiPrayerTime
);

export const getHighlighted = createSelector(
    [ getPrayersState ],
    prayers => prayers.highlighted
);

export const getIsIqama = createSelector(
    [ getPrayersState ],
    prayers => prayers.isIqama
);
export const getQrCode = createSelector(
    [ getPrayersState ],
    prayers => prayers.qrCode
);
export const getLogo = createSelector(
    [ getPrayersState ],
    prayers => prayers.logo
);
export const getQibla = createSelector(
    [ getPrayersState ],
    prayers => prayers.qibla
);
export const getBackgroundImage = createSelector(
    [ getPrayersState ],
    prayers => prayers.backgroundImage
);

export const getTvSettings = createSelector(
    [ getPrayersState ],
    prayers => prayers.tvSettings
);

export const getTvLangsTwo = createSelector(
    [ getPrayersState ],
    prayers => prayers.tvLangsTwo
);

export const getTvLangsOne = createSelector(
    [ getPrayersState ],
    prayers => prayers.tvLangsOne
);
export const getIsCsv = createSelector(
    [ getPrayersState ],
    prayers => prayers.isCsv
);
export const getHijriPreference = createSelector(
    [ getPrayersState ],
    prayers => prayers.hijriPreference
);
export const getEntity = createSelector(
    [ getPrayersState ],
    prayers => prayers.entity
);

export const getPrayerTime = createSelector(
    [ getPrayersState ],
    prayers => prayers.prayerTime
);

export const getAnnouncement = createSelector(
    [ getPrayersState ],
    prayers => prayers.announcement
);

export const getDarkMode = createSelector(
    [ getPrayersState ],
    prayers => prayers.darkMode
);

export const getDarkModeSetting = createSelector(
    [ getPrayersState ],
    prayers => prayers.darkModeStting
);
export const getForceWhite = createSelector(
    [ getPrayersState ],
    prayers => prayers.forceWhite
);
export const getFocusModeTimes = createSelector(
    [ getPrayersState ],
    prayers => prayers.focusModeTimes
);

export const getFontSize = createSelector(
    [ getPrayersState ],
    prayers => prayers.bigFontSize
);
export const getFontWeigth = createSelector(
    [ getPrayersState ],
    prayers => prayers.fontWeight
);

export const getAdhanAudioMode = createSelector(
    [ getPrayersState ],
    prayers => prayers.adhanAudioMode
);
export const getAcceptPortrait = createSelector(
    [ getPrayersState ],
    prayers => prayers.acceptPortrait
);
export const getScreenName = createSelector(
    [ getPrayersState ],
    prayers => prayers.screenName
);