import { getFocusMode, getAdhkarSalat } from '../../../services/selectors/appStatusSelectors';
import ClosePhone from '../../../views/app/features/closePhone';
import AdhkarSalat from '../../app/features/adhkarSalat';
import { withTranslation } from 'react-i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class AfterSalatActions extends PureComponent {

    constructor( props ) {
        super( props );
    }

    render () {

        const { children, focusMode, adhkarSalat } = this.props;

        return (
            <>
                { focusMode ? (
                    <ClosePhone />
                ) : adhkarSalat ? (
                    <AdhkarSalat />
                ) : (
                    children
                ) }
            </>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    focusMode: getFocusMode( state ),
    adhkarSalat: getAdhkarSalat( state ),
} );

export default connect( mapStateToProps )( withTranslation()( AfterSalatActions ) );
