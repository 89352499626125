import React from 'react';
import ReactPlayer from 'react-player';

const VideoLayout = ( { videoUrl } ) => {
    const handleOnReady = () => {
        return true;
    };

    return (
        <div style={ { width: '100%', height: '100%', backgroundColor: "#000" } }>
            <ReactPlayer
                url={ videoUrl }
                width="100%"
                height="100%"
                playing
                loop
                volume={ 1 }
                muted={ true }
                onStart={ handleOnReady }
                onEnded={ handleOnReady }
                config={ {
                    file: {
                        attributes: {
                            preload: "auto",
                        },
                    },
                } }
                onReady={ handleOnReady }
            />
        </div>
    );
};

export default React.memo( VideoLayout );
