import dayjs from 'dayjs';
import { setAdhan, setIqama, setShuruq, setThirdNight, setMiddleNight, setDarkMode, setFocusMode, setAdhkarSalat } from '../../../../actions/appStatus';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend( customParseFormat );
dayjs.extend( isBetween );

export const getNextPrayerTimeDelay = ( apiPrayerTimes, iqamaTimes ) => {
    const now = dayjs();
    let nextPrayerTime = dayjs().add( 1, 'day' );
    const times = [ ...Object.values( apiPrayerTimes ), ...Object.values( iqamaTimes ) ];
    times.forEach( time => {
        const prayerTimeToday = dayjs( time, "HH:mm" );
        if ( prayerTimeToday.isAfter( now ) && prayerTimeToday.isBefore( nextPrayerTime ) ) {
            nextPrayerTime = prayerTimeToday;
        }
    } );

    return nextPrayerTime.diff( now );
};

export const getPrayerNotificationCode = ( apiPrayerTimes, iqamaTimes ) => {
    try {
        const now = dayjs().format( "HH:mm" );
        const today = dayjs().day();
        const validTimes = [ "Fajr", "Dhuhr", "Asr", "Maghrib", "Isha" ];

        // Check iqama times except for Dhuhr on Friday
        if ( today === 5 && iqamaTimes.Dhuhr === now ) {
            return 'NONE';
        }
        const iqamaMatch = validTimes.some( time => iqamaTimes[ time ] === now );
        if ( iqamaMatch ) {
            return 'IQAMA';
        }
        const prayerMatches = {
            [ apiPrayerTimes.Sunrise ]: 'SUNRISE',
            [ apiPrayerTimes.Midnight ]: 'MIDNIGHT',
            [ apiPrayerTimes.Imsak ]: 'IMSAK'
        };

        return prayerMatches[ now ] || 'NONE';
    } catch ( error ) {
        return { error: "Error in determining prayer time", code: 'ERROR' };
    }
};

export const adhanTime = function ( adhan ) {
    try {
        const now = dayjs().format( "HH:mm" );
        const today = dayjs().day();
        const validTimes = [ "Fajr", "Dhuhr", "Asr", "Maghrib", "Isha" ];
        if ( today === 5 && adhan.Dhuhr === now ) {
            return false;
        }
        for ( const time of validTimes ) {
            if ( adhan[ time ] === now ) {
                return time;
            }
        }
        return false;
    } catch ( error ) {
        return false;
    }
};

export const iqamaTime = function ( iqama ) {
    try {
        const now = dayjs().format( "HH:mm" );
        const today = new Date().getDay();
        const validTimes = [ "Fajr", "Dhuhr", "Asr", "Maghrib", "Isha" ];

        if ( today === 5 && iqama.Dhuhr === now ) {
            return false;
        }

        for ( const time of validTimes ) {
            if ( iqama[ time ] === now ) {
                return time;
            }
        }

        return false;

    } catch ( error ) {
        return false;
    }
};


export const tvNotificationTime = function ( tvPrayer ) {
    try {
        const now = dayjs().format( "HH:mm" );
        let notificationTime = null;
        const { Sunrise, Midnight, Imsak } = tvPrayer;
        switch ( now ) {
            case Sunrise:
                notificationTime = 1;
                break;
            case Midnight:
                notificationTime = 2;
                break;
            case Imsak:
                notificationTime = 3;
                break;
        }
        return notificationTime;
    } catch ( error ) {
        return null
    }
};

const handleAdhanTime = ( dispatch, isAdhanTime, adhanMode, isItDarkMode ) => {
    const adhanModeStatus = adhanMode[ isAdhanTime ];
    dispatch( setAdhan( { status: true, name: isAdhanTime } ) );
    const adhanTimeout = setTimeout( () => {
        if ( !adhanModeStatus ) {
            dispatch( setAdhan( { status: false, name: '' } ) );
        }
    }, 110000 );

    if ( isAdhanTime === "Maghrib" ) {
        dispatch( setDarkMode( isItDarkMode ) );
    }

    return () => clearTimeout( adhanTimeout );
};

const handleIqamaTime = ( dispatch, isIqamaTime, focusModeTimes ) => {
    dispatch( setIqama( { status: true, name: isIqamaTime } ) );

    const iqamaTimeout = setTimeout( () => {
        dispatch( setIqama( { status: false, name: '' } ) );

        const focusModeTime = focusModeTimes[ isIqamaTime ];
        if ( focusModeTime && focusModeTime > 0 ) {
            dispatch( setFocusMode( true ) );
            const focusModeTimeout = setTimeout( () => {
                dispatch( setFocusMode( false ) );
                dispatch( setAdhkarSalat( true ) );
            }, Number( focusModeTime ) * 60000 );
            return () => clearTimeout( focusModeTimeout );
        }
    }, 110000 );

    return () => clearTimeout( iqamaTimeout );
};

const handleTvNotification = ( dispatch, tvNotification, turnOfDarkMode ) => {
    let timeout;

    switch ( tvNotification ) {
        case 1: // Sunrise
            dispatch( setShuruq( true ) );
            dispatch( setDarkMode( turnOfDarkMode ) );
            timeout = setTimeout( () => {
                dispatch( setShuruq( false ) );
            }, 110000 );
            return () => clearTimeout( timeout );
        case 2:
            dispatch( setMiddleNight( true ) );
            timeout = setTimeout( () => {
                dispatch( setMiddleNight( false ) );
            }, 110000 );
            return () => clearTimeout( timeout );
        case 3:
            dispatch( setThirdNight( true ) );
            timeout = setTimeout( () => {
                dispatch( setThirdNight( false ) );
            }, 110000 );
            return () => clearTimeout( timeout );
        default:
            return;
    }
};

export const updatePrayerStatus = ( dispatch, apiPrayerTime, iqamaPrayerTime, isIqama, darkModeStting, focusModeTimes, adhanMode ) => {
    try {
        let isAdhanTime, isTheIqamaTime, tvNotification;
        const isItDarkMode = String( darkModeStting ) === 'auto' ? true : ( darkModeStting === 'false' ? false : true );
        const turnOfDarkMode = String( darkModeStting ) === 'auto' ? false : ( darkModeStting === 'false' ? false : true );

        // Check Adhan and Iqama times
        isAdhanTime = adhanTime( apiPrayerTime );
        isTheIqamaTime = iqamaTime( iqamaPrayerTime );
        tvNotification = tvNotificationTime( apiPrayerTime );

        if ( isAdhanTime ) {
            return handleAdhanTime( dispatch, isAdhanTime, adhanMode, isItDarkMode );
        }

        if ( isIqama && isTheIqamaTime ) {
            return handleIqamaTime( dispatch, isTheIqamaTime, focusModeTimes );
        }

        if ( tvNotification ) {
            return handleTvNotification( dispatch, tvNotification, turnOfDarkMode );
        }

        return () => { };
    } catch ( e ) {
        return false;
    }
};

export const isAfterMaghrib = ( maghribTime ) => {
    const now = dayjs();
    const maghrib = dayjs( maghribTime, "HH:mm" );
    return now.isAfter( maghrib );
};

export const resetAllStates = ( dispatch ) => {
    dispatch( setAdhan( false ) );
    dispatch( setIqama( false ) );
    dispatch( setShuruq( false ) );
    dispatch( setThirdNight( false ) );
    dispatch( setMiddleNight( false ) );
};