import axios from "axios";
import { localPrayerTime } from "../Utils/fetchLocalStorage";
import { fetchDataFromServer, fetchDataFromLocalStorage, handleVersionChange, isOnline, managePhoneNumStorage, isLocalStorageSupported, isOverseasCountry } from '../Utils/apiService';
import { getPrayerTiming, postPrayerTiming } from '../services/app/prayers/api'
import { getPayloadFromLocalStorage, setAndProcessPhone } from '../services/app/mapping';
import Purify from 'moonode-salat-time-purify';
import { initializeState } from "./prayer";
import {
    POST_ADMIN_PRAYER_TV,
    GET_ADMIN_PRAYER_TV,
    SET_SOME_POSTS,
    GET_TV_CODE
} from "./types";

const { structureData } = Purify;

export const getPrayerEntityTv = ( phoneNum, online = true ) => async dispatch => {
    let payload;

    try {
        if ( online ) {
            managePhoneNumStorage( phoneNum );
            const data = await fetchDataFromServer( phoneNum );
            if ( isLocalStorageSupported() ) {
                const currentVersion = localStorage.getItem( 'version' );
                handleVersionChange( currentVersion, data.data.version, phoneNum, data );
            }
            if ( isOverseasCountry( data.data.entity.country ) ) {
                payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
            } else {
                payload = data.data;
            }

        } else {
            if ( isLocalStorageSupported() ) {
                payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
            }
        }
    } catch ( error ) {
        try {
            // console.log( error );
            payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
        } catch ( e ) {
            // console.log( e ); // Log error when no data received or other issues
        }
    }

    if ( payload ) {
        dispatch( {
            type: GET_ADMIN_PRAYER_TV,
            payload: payload
        } );
    }
};

export const postPrayerEntityTv = ( extId, online = true, code = '' ) => async dispatch => {
    try {
        let data;
        if ( online ) {
            // to do getPrayerTiming
            data = await postPrayerTiming( extId, code );
            if ( data ) {
                await setAndProcessPhone( extId, data );
            }

        }
        if ( !data ) {
            const payload = await getPayloadFromLocalStorage( extId );
            if ( payload ) {
                const parsedPayload = JSON.parse( payload );
                data = parsedPayload;
            }
        }
        if ( data ) {
            let structuredData = await structureData( data?.data, true );
            dispatch( {
                type: POST_ADMIN_PRAYER_TV,
                payload: structuredData
            } );
            dispatch( initializeState( structuredData ) );
        }

        const token = data?.data?.token;

        return token
    } catch ( error ) {
        try {
            // console.log( error );
            // payload = await fetchDataFromLocalStorage( extId, localPrayerTime );
        } catch ( e ) {
            // console.log( e ); // Log error when no data received or other issues
        }
    }
};
/**
 * Post question
 * @param {*} user
 */
export const somePostsManagments = ( somePostsLength, tvPosts, originalPosts ) => async dispatch => {

    try {

        if ( tvPosts <= 1 ) {

            dispatch( {
                type: SET_SOME_POSTS,
                payload: originalPosts.length
            } );
        } else {
            dispatch( {
                type: SET_SOME_POSTS,
                payload: tvPosts
            } );

        }

    } catch ( error ) {
        // console.log( error );
    }
};


export const getDigits = ( code ) => async ( dispatch ) => {
    try {
        const res = await axios.get( `/tapy/masjid/tv/new/digits/${ code }` );
        const tvCode = res?.data?.tvCode;
        if ( tvCode && typeof tvCode.code === 'string' && tvCode.code.length === 6 ) {
            localStorage.setItem( "code", tvCode.code );
            dispatch( {
                type: GET_TV_CODE,
                payload: tvCode
            } );

            return tvCode.code;
        } else {
            return null; // Or throw an error
        }
    } catch ( error ) {
        console.error( 'Error fetching TV code:', error );
    }
};


export const setDigits = ( code ) => async dispatch => {
    try {
        const res = await axios.get( `/tapy/masjid/tv/get/digits/${ code }` );
        return res.data;
    } catch ( error ) {
        const cachedUrl = localStorage.getItem( "45%643D" );
        if ( cachedUrl ) {
            window.location.replace( cachedUrl );
        }
        return false;
    }
};

