import React, { useState, useEffect, useMemo } from 'react';
import GlassedBox from '../../../../layouts/glassmorphism/boxGlassed';
import { setAdhkarSalat } from '../../../../actions/appStatus';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import '../features.css';

const AdhkarComponent = () => {
    const { i18n, t } = useTranslation();
    const language = i18n.language;
    const dispatch = useDispatch();

    const adhkars = useMemo( () => [
        { text: t( 'adhkar.istighfar' ), duration: 10 },
        { text: t( 'adhkar.salam_dua' ), duration: 15 },
        { text: t( 'adhkar.tawhid_dua' ), duration: 23 },
        { text: t( 'adhkar.tawhid_long' ), duration: 35 },
        { text: t( 'adhkar.subhanallah' ), duration: 15 },
        { text: t( 'adhkar.alhamdulillah_subhanallah' ), duration: 25 },
        { text: t( 'adhkar.takbir_alhamdulillah_subhanallah' ), duration: 35 },
        { text: t( 'adhkar.tawhid_short' ), duration: 25 },
        { text: t( 'adhkar.tawhid_yahee' ), duration: 27 },
        { text: t( 'adhkar.ayat_kursi' ), duration: 50 },
        { text: t( 'adhkar.surah_ikhlas' ), duration: 25 },
        { text: t( 'adhkar.surah_falaq' ), duration: 30 },
        { text: t( 'adhkar.surah_nas' ), duration: 35 }
    ], [ t ] );

    const [ currentIndex, setCurrentIndex ] = useState( 0 );
    const [ fadeClass, setFadeClass ] = useState( '' );

    useEffect( () => {
        if ( adhkars.length === 0 ) return;

        const currentAdhkar = adhkars[ currentIndex ];
        const duration = currentAdhkar.duration * 1000;

        setFadeClass( '' );
        setTimeout( () => setFadeClass( 'fadeInUp-animation' ), 10 );

        const timeout = setTimeout( () => {
            if ( currentIndex < adhkars.length - 1 ) {
                setCurrentIndex( ( prevIndex ) => prevIndex + 1 );
            } else {

                dispatch( setAdhkarSalat( false ) );
            }
        }, duration );

        return () => {
            clearTimeout( timeout );
        };
    }, [ currentIndex, adhkars, dispatch ] );

    const glassedBoxStyles = {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        margin: '0',
        padding: '0',
        height: '94%',
        margin: '1vh',
    };

    const innerBoxStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    function calculateFontSize ( text ) {
        const length = text.length;
        let fontSize;

        const baseFontSize = {
            ar: 1.3,
            ur: 1.2,
            ja: 0.8,
            en: 1.1,
            fr: 1.1,
            bn: 0.9
        };

        const langFactor = baseFontSize[ language ] || 1.1;

        if ( length <= 50 ) {
            fontSize = 14 * langFactor;
        } else if ( length <= 100 ) {
            fontSize = 12 * langFactor;
        } else if ( length <= 150 ) {
            fontSize = 10 * langFactor;
        } else if ( length <= 200 ) {
            fontSize = 8 * langFactor;
        } else if ( length <= 300 ) {
            fontSize = 6 * langFactor;
        } else {
            const minSize = 5 * langFactor;
            const maxSize = 12 * langFactor;
            const minLength = 50;
            const maxLength = 500;
            fontSize = maxSize - ( ( length - minLength ) / ( maxLength - minLength ) ) * ( maxSize - minSize );
        }

        return fontSize + 'vh';
    }

    const currentAdhkarText = adhkars[ currentIndex ].text;

    return (
        <GlassedBox gmode="gm-dmode" styles={ glassedBoxStyles }>
            <Box sx={ innerBoxStyles }>
                <p
                    className={ `arrkgt4 ${ fadeClass }` }
                    style={ {
                        fontSize: calculateFontSize( currentAdhkarText ),
                        textAlign: 'center',
                        margin: '0',
                        padding: '0',
                        direction: 'rtl',
                        maxWidth: '90%',
                        lineHeight: '1.2',
                        wordSpacing: '4.5rem',
                    } }
                >
                    { currentAdhkarText }
                </p>
            </Box>
        </GlassedBox>
    );
};

export default React.memo( AdhkarComponent );
