import React, { Suspense } from 'react';

import PrayerPortrait from '../../../views/pages/prayersPortrait/mobile';
import NotificationLayout from '../../../views/app/features/notifications';
import StateHandlerWrapper from '../../../views/wrappers/state';
//socket handler
import SocketIoWrapperHandler from '../../../views/wrappers/socket';
//prayer getter and loading
import PrayerGetterAndLoading from '../../../views/wrappers/salat';
//lang and theme
import ApplicationSettings from '../../../views/wrappers/settings';


const View = () => {
    return (
        <Suspense fallback={ <></> } >
            <PrayerGetterAndLoading>
                <SocketIoWrapperHandler>
                    <ApplicationSettings>
                        <NotificationLayout>
                            <StateHandlerWrapper>
                                <PrayerPortrait />
                            </StateHandlerWrapper>
                        </NotificationLayout>
                    </ApplicationSettings>
                </SocketIoWrapperHandler>
            </PrayerGetterAndLoading >
        </Suspense>
    );
};

export default React.memo( View );  
