import useNavigatorOnLine from '../../../hooks/onlineStatus';
import { getFlyer } from "../../../actions/flyer";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';

const FlyerScreenGetterLayout = ( { getFlyer, children, digits, code } ) => {
    const online = useNavigatorOnLine();
    const handleFullscreen = () => {
        try {
            if ( !document.fullscreenElement ) {
                document.documentElement.requestFullscreen().catch( ( err ) => {
                    // console.warn( `Error attempting to enable fullscreen: ${ err.message }` );
                } );
            } else {
                document.exitFullscreen().catch( ( err ) => {
                    // console.warn( `Error attempting to exit fullscreen: ${ err.message }` );
                } );
            }
        } catch ( error ) {
            // console.error( "An error occurred while trying to switch fullscreen mode:", error );
        }
    };

    useEffect( () => {
        const fetchFlyerData = async () => {
            getFlyer( digits, online, code );
        };
        fetchFlyerData();
        const intervalFetch = setInterval( () => {
            fetchFlyerData();
        }, 1200000 );

        return () => {
            clearInterval( intervalFetch );
        };

    }, [ digits, getFlyer, online ] );

    return (
        <Box onClick={ handleFullscreen }>
            { children }
        </Box>
    );
};

const mapStateToProps = ( state ) => ( {
    admin: state.admin,
} );



export default connect( mapStateToProps, { getFlyer } )( React.memo( FlyerScreenGetterLayout ) );
