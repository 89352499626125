import React, { Fragment, useState, useEffect } from "react";

import myAdhanArabe from './adanarabe-original.webp';
import myIqamaArabe from './iqamaarabe-original.webp';
import image1 from './sunriseen-original.gif'
import image2 from './midnighten-original.gif'
import image3 from './thirdnighten-original.gif'
import AdhanText from '../../adhanFooter';

const Video = ( {
    tvPrayer,
    isAdhan,
    setTvNotificationAndIqama,
    tvNotification,
    isTvNotification } ) => {
    const [ currentCount, setCurrentCount ] = useState( 105 )

    const {
        defaultAdhan = myAdhanArabe,
        defaultIqama = myIqamaArabe,
        defaultShuruk = image1,
        defaultMidnight = image2,
        defaultThird = image3
    } = tvPrayer?.visualControl?.entity?.entityImage || {};
    let tvNotificationArray = [ defaultShuruk, defaultShuruk, defaultMidnight, defaultThird ];

    useEffect( () => {
        try {
            const intervalAds = setInterval( () => {
                if ( currentCount >= 0 ) {
                    setCurrentCount( currentCount - 1 );
                } else {
                    setCurrentCount( 105 );
                    setTvNotificationAndIqama( false, 0 )
                }
            }, 1000 );
            return () => {
                clearInterval( intervalAds )
            }
        } catch ( error ) {
            console.log( error );
        }
    }, [ currentCount ] )

    return (
        <Fragment>
            <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } }>
                { isAdhan ?
                    <img
                        allowFullScreen
                        data-autoplay={ true }
                        controls={ false }
                        muted loop id="myVideo"
                        src={ isTvNotification ? tvNotificationArray[ tvNotification ] : defaultAdhan } type="gif"
                        alt="adhan"
                    />
                    :
                    <img
                        allowFullScreen
                        data-autoplay={ true }
                        controls={ false }
                        muted loop id="myVideo"
                        src={ defaultIqama } type="gif"
                    />
                }
                { !isTvNotification && <AdhanText isAdhan={ isAdhan } /> }
            </div>
        </Fragment>

    );
};

export default Video;

