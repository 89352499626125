import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { convertTimeWorld } from '../../../services/formatDate.js';


const Iqama = ( { tvPrayer: { iqamaTime, tvSettings, tomorrowIqamaTime, tvLangsOne, tvLangsTwo, highlighted }, arabic, calculeNextIqama, iqama, langs, color, isArabicFont } ) => {
    const { f, d, a, m, i } = highlighted;

    const [ state, setstate ] = useState( {
        Isha: false,
        Maghrib: false,
        Asr: false,
        Dhuhr: false,
        Fajr: false,
        iDiff: false,
        mDiff: false,
        aDiff: false,
        dDiff: false,
        fDiff: false,
    } )

    useEffect( () => {
        let now = format( new Date(), "HH:mm" )
        if ( calculeNextIqama( iqamaTime, "Isha" ) ) {
            setstate( { Isha: true, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Maghrib" ) ) {
            setstate( { Maghrib: true, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Asr" ) ) {
            setstate( { Asr: true, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Dhuhr" ) ) {
            setstate( { Dhuhr: true, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Fajr" ) && now > iqamaTime.Fajr ) {
            setstate( { Fajr: true, iDiff: tomorrowIqamaTime.iIsDiff, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Fajr" ) ) {
            setstate( { Fajr: true, iDiff: false, mDiff: false, aDiff: false, dDiff: false, fDiff: false } );
        }
    }, [ iqama, iqamaTime ] )
    return (
        <div className="container-1"  >
            <div className={ `box-1 ${ state.Isha ? color : ( state.iDiff && i ) ? "box-highlighted2" : "box-highlighted-none" }` } id="xmoxshadow" style={ { margin: '5x', maxHeight: '6vw' } }>
                <h3 style={ { fontSize: "5.7vw", marginTop: '-0.3vh', fontWeight: 400, color: state.Isha || ( state.iDiff && i ) ? "#fff" : "#000" } }> { state.iDiff ? convertTimeWorld( tvSettings.isTimeConvention, tomorrowIqamaTime.Isha ) : convertTimeWorld( tvSettings.isTimeConvention, iqamaTime.Isha ) }</h3>
            </div>
            <div className={ `box-2 ${ state.Maghrib ? color : ( state.mDiff && m ) ? "box-highlighted2" : "box-highlighted-none" }` } id="xmoxshadow" style={ { margin: '5x', maxHeight: '6vw' } }>
                <h3 style={ { fontSize: "5.7vw", marginTop: '-0.3vh', fontWeight: 400, color: state.Maghrib || ( state.mDiff && m ) ? "#fff" : "#000" } }> { state.mDiff ? convertTimeWorld( tvSettings.isTimeConvention, tomorrowIqamaTime.Maghrib ) : convertTimeWorld( tvSettings.isTimeConvention, iqamaTime.Maghrib ) }</h3>
            </div>
            <div className={ `box-3 ${ state.Asr ? color : ( state.aDiff && a ) ? "box-highlighted2" : "box-highlighted-none" }` } id="xmoxshadow" style={ { margin: '5x', maxHeight: '6vw' } }>
                <h3 style={ { fontSize: "5.7vw", marginTop: '-0.3vh', fontWeight: 400, color: state.Asr || ( state.aDiff && a ) ? "#fff" : "#000" } }> { state.aDiff ? convertTimeWorld( tvSettings.isTimeConvention, tomorrowIqamaTime.Asr ) : convertTimeWorld( tvSettings.isTimeConvention, iqamaTime.Asr ) }</h3>
            </div>
            <div className={ `box-4 ${ state.Dhuhr ? color : ( state.dDiff && d ) ? "box-highlighted2" : "box-highlighted-none" }` } id="xmoxshadow" style={ { margin: '5x', maxHeight: '6vw' } }>
                <h3 style={ { fontSize: "5.7vw", marginTop: '-0.3vh', fontWeight: 400, color: state.Dhuhr || ( state.dDiff && d ) ? "#fff" : "#000" } }> { state.dDiff ? convertTimeWorld( tvSettings.isTimeConvention, tomorrowIqamaTime.Dhuhr ) : convertTimeWorld( tvSettings.isTimeConvention, iqamaTime.Dhuhr ) }</h3>
            </div>
            <div className={ `box-5 ${ state.Fajr ? color : ( state.fDiff && f ) ? "box-highlighted2" : "box-highlighted-none" }` } id="xmoxshadow" style={ { margin: '5x', maxHeight: '6vw' } }>
                <h3 style={ { fontSize: "5.7vw", marginTop: '-0.3vh', fontWeight: 400, color: state.Fajr || ( state.fDiff && f ) ? "#fff" : "#000" } }> { state.fDiff ? convertTimeWorld( tvSettings.isTimeConvention, tomorrowIqamaTime.Fajr ) : convertTimeWorld( tvSettings.isTimeConvention, iqamaTime.Fajr ) }</h3>
            </div>
            <div className="box-6 box-highlighted-none" style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px', maxHeight: '6vw' } } id="xmoxshadow">
                <h3 className={ `${ isArabicFont ? 'arabic' : 'rumi' }` } style={ { fontSize: !arabic ? "4.5vw" : "3.5vw", color: "#000", fontWeight: 400 } }>
                    { arabic ? langs.iqama[ Number( tvLangsOne ) ] : langs.iqama[ Number( tvLangsTwo ) ] }  </h3>
            </div>
        </div>
    )
}

Iqama.propTypes = {

}

export default Iqama
