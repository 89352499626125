// TomorrowTitle.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { getCurrentLanguage } from '../../services/selectors/languageSelectors';

const TitleComponent = React.memo( ( { text, isTomorrow = false, textStyle } ) => {
    const { t } = useTranslation();
    const currentLanguage = useSelector( getCurrentLanguage );
    const typographyStyles = {
        justifyContent: 'center',
        width: '100%',
        fontSize: isTomorrow ? '2vw' : '2.5vw',
        mt: '0.2vh',
        overflow: 'hidden',
        alignContent: 'center',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        ...textStyle,

    };

    return (
        <Box sx={ { height: '100%', width: '100%' } }>
            <Typography
                sx={ typographyStyles }
                className={ ` ${ currentLanguage === 'ar' ? 'arrkgt4' : '' }` }
            >
                <bdi>
                    { text } { isTomorrow && t( 'common.tomorrow' ) }
                </bdi>
            </Typography>
        </Box>
    );
}, ( prevProps, nextProps ) => {
    return prevProps.text === nextProps.text && prevProps.isTomorrow === nextProps.isTomorrow && prevProps.currentLanguage === nextProps.currentLanguage && prevProps.t === nextProps.t && prevProps.textStyle === nextProps.textStyle;
} );
// export with memo
export default React.memo( TitleComponent );
