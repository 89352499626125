import React, { Fragment, Component } from 'react';
import { postPrayerEntityTv, somePostsManagments } from "../../../actions/admin";
import { getPlaylistLength } from '../../../services/selectors/playerSelectors'; // Import the selector
import { togglePlay } from '../../../actions/player';
import { DATA } from '../../../services/Lang.js';
import PostTv from '../../wrappers/player/index';
import TvUi from '../../pages/modern/Index';
import '../../../styles/sultan.min.css';
import { connect } from "react-redux";

class Television extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            tvInfo: [],
            isAds: false,
            isOk: true,
            tvSettings: null,
            fullScreenMode: false,
            arabic: true,
            adhan: false,
            iqama: false,
            intervalFetch: null,
            intervalPlayer: null,
            intervalCancelAds: null,
            intervalStartAds: null,
            tvAdsActivated: false,
            isTvNotification: false,
            tvNotification: 0,
        };
        this.turningOffAds = this.turningOffAds.bind( this );
        this.handleFullscreen = this.handleFullscreen.bind( this );
    }

    turningOffAds = ( bool ) => {
        this.setState( { ...this.state, isAds: bool } );
    }

    switchLangue = () => {
        this.setState( { ...this.state, arabic: !this.state.arabic } );
    }

    setAdhan = ( bool ) => {
        this.setState( { ...this.state, adhan: bool } );
    }

    setIqama = ( bool ) => {
        this.setState( { ...this.state, iqama: bool } );
    }

    handleFullscreen = () => {
        try {
            if ( !document.fullscreenElement ) {
                if ( document.documentElement.requestFullscreen ) {
                    document.documentElement.requestFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to enable fullscreen: ${ err.message }` );
                    } );
                }
            } else {
                if ( document.exitFullscreen ) {
                    document.exitFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to exit fullscreen: ${ err.message }` );
                    } );
                }
            }
        } catch ( error ) {
            console.error( "An error occurred while trying to switch fullscreen mode:", error );
        }
    }

    setTvNotification = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool
        } ) );
    }

    setTvNotificationAndIqama = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool,
            iqama: bool,
        } ) );
    }

    componentDidMount () {
        try {
            const { token, playlistLength, dispatch } = this.props;
            this.props.postPrayerEntityTv( token );
            const intervalFetch = setInterval(
                () => this.props.postPrayerEntityTv( token ),
                1200000
            );

            let intervalPlayer = null;
            if ( playlistLength > 0 ) {
                intervalPlayer = setInterval(
                    () => dispatch( togglePlay( true ) ),
                    180000 // 3 mins
                );
            }

            this.setState( { intervalFetch, intervalPlayer } );

        } catch ( error ) {
            console.error( "An error occurred in componentDidMount:", error );
        }
    }

    componentWillUnmount () {
        clearInterval( this.state.intervalFetch );
        if ( this.state.intervalPlayer ) {
            clearInterval( this.state.intervalPlayer );
        }
    }

    render () {
        const { adminLoading, tvPrayer, tvSettings } = this.props.admin;
        const { token, time, reload, playing } = this.props;
        return (
            adminLoading ? <div style={ {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '20px'
            } }>
                Loading...
            </div> : <Fragment>
                <div onClick={ () => this.handleFullscreen() }>
                    { playing ?
                        <div
                            style={ {
                                width: '100%',
                                height: '100vh',
                                display: 'flex',
                                flexDirection: 'column'
                            } }
                        >
                            <PostTv />
                        </div >

                        :
                        <TvUi
                            time={ time }
                            reload={ reload }
                            setTvNotificationAndIqama={ this.setTvNotificationAndIqama }
                            setTvNotification={ this.setTvNotification }
                            tvNotification={ this.state.tvNotification }
                            isTvNotification={ this.state.isTvNotification }
                            setIqama={ this.setIqama }
                            setAdhan={ this.setAdhan }
                            turningOffAds={ this.turningOffAds }
                            token={ token }
                            tvPrayer={ tvPrayer }
                            color={ tvSettings?.color }
                            langs={ DATA() }
                            switchLangue={ this.switchLangue }
                            tvAdsActivated={ this.state.tvAdsActivated }
                            arabic={ this.state.arabic }
                            adhan={ this.state.adhan }
                            iqama={ this.state.iqama }
                        /> }
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ( {
    admin: state.admin,
    playlistLength: getPlaylistLength( state ), // map playlistLength from state
} );

export default connect( mapStateToProps, { postPrayerEntityTv, somePostsManagments } )( Television );
