import { getFlyerData } from '../services/app/flyer/api'

import {
    GET_FLYER,
} from "./types";

export const getFlyer = ( digits, online, code ) => async dispatch => {
    try {
        let data;
        if ( online ) {
            data = await getFlyerData( digits, code );
        }
        if ( data ) {
            dispatch( {
                type: GET_FLYER,
                payload: data
            } );
        }
    } catch ( error ) {
        console.log( 'be more structured' );
    }
};



