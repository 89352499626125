import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

const LazySocketIoWrapperHandler = React.lazy( () => import( '../socket' ) );

const SocketConditionalWrapper = ( { children } ) => {
    const location = useLocation();
    const searchParams = new URLSearchParams( location.search );
    const useSocket = searchParams.get( 'usesocket' );
    if ( useSocket === 'false' ) {
        return <>{ children }</>;
    } else {
        return (
            <Suspense fallback={ <></> }>
                <LazySocketIoWrapperHandler>{ children }</LazySocketIoWrapperHandler>
            </Suspense>
        );
    }
};

export default React.memo( SocketConditionalWrapper );
