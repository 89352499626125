import React, { Suspense } from 'react';
import { getApiPrayerTime } from '../../../../../services/selectors/prayersSelectors';
import GlassedBox from '../../../../../layouts/glassmorphism/boxGlassed';
import TextFadeComponent from '../../../../../layouts/TitleComponent';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../kvbt3e.min.css';

const SkeletonLoader = React.lazy( () => import( '../../../../../layouts/skeleton' ) ); // Adjust the path as necessary

const TierNight = () => {
    const { t } = useTranslation();
    const apiPrayerTime = useSelector( getApiPrayerTime );

    const glassedBoxStyles = {
        width: '33.33%',
        borderRadius: '2vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '1.5vh',
        marginLeft: '2.5vh',
        overflow: 'hidden',
    };

    const typographyStyles = {
        fontSize: {
            xs: '3.5vh',
            sm: '3.5vh',
            md: '3.5vh',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    };

    const spanStyles = {
        margin: '2vh',
        fontSize: {
            xs: '4.5vh',
            sm: '4.5vh',
            md: '4.5vh',
        },
    };

    return (
        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles }>
            <Box sx={ typographyStyles }>
                <TextFadeComponent text={ t( 'salat.lastthird' ) } textStyle={ typographyStyles } />
                <Typography component="span" sx={ spanStyles }>
                    { apiPrayerTime?.Imsak }
                </Typography>
            </Box>
        </GlassedBox>
    );
};

export default TierNight;
