import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import usePrayerTimeState from '../../../hooks/prayerState';

import {
    getLogo,
    getIsIqama,
    getIqamaTime,
    getTvSettings,
    getPrayerTime,
    getScreenName,
    getHighlighted,
    getApiPrayerTime,
    getTomorrowIqamaTime,
    getTomorrowApiPrayerTime,
} from '../../../services/selectors/prayersSelectors';

import { convertTimeWorld } from '../../../services/formatDate';

const MobilePrayer = ( { children } ) => {

    const { t } = useTranslation();
    const logo = useSelector( getLogo );
    const isIqama = useSelector( getIsIqama );
    const iqamaTime = useSelector( getIqamaTime );
    const tvSettings = useSelector( getTvSettings );
    const prayerTime = useSelector( getPrayerTime );
    const screenName = useSelector( getScreenName );
    const highlighted = useSelector( getHighlighted ) || {};  // Fallback to an empty object
    const apiPrayerTime = useSelector( getApiPrayerTime );
    const tomorrowIqamaTime = useSelector( getTomorrowIqamaTime );
    const tomorrowApiPrayerTime = useSelector( getTomorrowApiPrayerTime );
    const [ jumuaTimes, setJumuaTimes ] = useState( [] );
    const state = usePrayerTimeState( iqamaTime, tomorrowIqamaTime );
    const { f, d, a, m, i } = highlighted;

    useEffect( () => {
        const times = [];
        try {
            if ( prayerTime?.Jumua ) times.push( { time: prayerTime.Jumua, lang: prayerTime.JumuaLang } );
            if ( prayerTime?.toggleJumua ) times.push( { time: prayerTime.Jumua2, lang: prayerTime.Jumua2Lang } );
            if ( prayerTime?.toggleJumua2 ) times.push( { time: prayerTime.Jumua3, lang: prayerTime.Jumua3Lang } );
            if ( prayerTime?.toggleJumua3 ) times.push( { time: prayerTime.Jumua4, lang: prayerTime.Jumua4Lang } );

            setJumuaTimes( times );
        } catch ( error ) {
            times.push( { time: 'N/A', lang: "" } );
            setJumuaTimes( times );
        }
    }, [ prayerTime ] );

    const adhanTimes = useCallback( () => [
        { key: 'fajr', time: apiPrayerTime?.Fajr || '-', nextTime: tomorrowApiPrayerTime?.Fajr, state: state.Fajr, diff: ( state.fDiff && f && isIqama ) },
        { key: 'shuruq', time: apiPrayerTime?.Shuruq || '-', nextTime: tomorrowApiPrayerTime?.Shuruq, state: state.Shuruq },
        { key: 'dhuhr', time: apiPrayerTime?.Dhuhr || '-', nextTime: tomorrowApiPrayerTime?.Dhuhr, state: state.Dhuhr, diff: ( state.dDiff && d && isIqama ) },
        { key: 'asr', time: apiPrayerTime?.Asr || '-', nextTime: tomorrowApiPrayerTime?.Asr, state: state.Asr, diff: ( state.aDiff && a && isIqama ) },
        { key: 'maghrib', time: apiPrayerTime?.Maghrib || '-', nextTime: tomorrowApiPrayerTime?.Maghrib, state: state.Maghrib, diff: ( state.mDiff && m && isIqama ) },
        { key: 'isha', time: apiPrayerTime?.Isha || '-', nextTime: tomorrowApiPrayerTime?.Isha, state: state.Isha, diff: ( state.iDiff && i && isIqama ) },
    ], [ apiPrayerTime, tomorrowApiPrayerTime, state, f, d, a, m, i ] );

    const iqamaTimes = useCallback( () => [
        { key: 'fajr', time: iqamaTime?.Fajr || '-', nextTime: tomorrowIqamaTime?.Fajr, state: state.Fajr, diff: state.fDiff && f },
        { key: 'shuruq', time: iqamaTime?.Shuruq || '-', nextTime: tomorrowIqamaTime?.Shuruq, state: state.Shuruq },
        { key: 'dhuhr', time: iqamaTime?.Dhuhr || '-', nextTime: tomorrowIqamaTime?.Dhuhr, state: state.Dhuhr, diff: state.dDiff && d },
        { key: 'asr', time: iqamaTime?.Asr || '-', nextTime: tomorrowIqamaTime?.Asr, state: state.Asr, diff: state.aDiff && a },
        { key: 'maghrib', time: iqamaTime?.Maghrib || '-', nextTime: tomorrowIqamaTime?.Maghrib, state: state.Maghrib, diff: state.mDiff && m },
        { key: 'isha', time: iqamaTime?.Isha || '-', nextTime: tomorrowIqamaTime?.Isha, state: state.Isha, diff: state.iDiff && i },
    ], [ iqamaTime, tomorrowIqamaTime, state, f, d, a, m, i ] );

    // Function to check if the content is Arabic
    const isArabic = ( text ) => /[\u0600-\u06FF]/.test( text );

    return (
        apiPrayerTime && (
            <Box
                className="moonode_background_blue"
                sx={ {
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '0.1px solid #000',
                    borderRadius: '1.5vh',
                    boxSizing: 'border-box',
                } }
            >
                <Box
                    sx={ {
                        width: '100%',
                        height: '100%',
                        padding: 2,
                        boxSizing: 'border-box',
                        display: 'grid',
                        gridTemplateRows: 'repeat(9, 1fr)',
                        gridGap: '10px',
                    } }
                >
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 12 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                            >
                                <marquee direction="left" width="200" bgcolor="white" style={ { width: '100%' } }>
                                    <Typography variant="h2" sx={ { color: '#000', fontWeight: 350 } } className={ isArabic( screenName ) ? 'arabic' : '' }>
                                        { screenName }
                                        <img src={ logo } alt="logo" style={ { width: '7vh', height: '6vh', marginLeft: '1.5vh', borderRadius: "20%" } } />
                                    </Typography>
                                </marquee>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ 'moonode_heighlight_blue' }
                            >
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        fontWeight: 550,
                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.salawat' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.salawat' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ 'moonode_heighlight_blue' }
                            >
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        fontWeight: 550,
                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.adhan' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.adhan' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ 'moonode_heighlight_blue' }
                            >
                                <Typography
                                    sx={ {
                                        color: '#fff',
                                        fontWeight: 550,
                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.iqama' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.iqama' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Fajr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Fajr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.fajr' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.fajr' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Fajr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Fajr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 0 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 0 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Fajr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Fajr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 0 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 0 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Shuruq */ }
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 12 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center', // Centering the text horizontally
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Shuruq ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Shuruq ? '#fff' : 'none',

                                        textAlign: 'center',
                                        mr: 10
                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.shuruq' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.shuruq' ) } { " " }
                                </Typography>
                                <Typography
                                    sx={ {
                                        color: state.Shuruq ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, apiPrayerTime?.Sunrise ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, apiPrayerTime?.Sunrise ) }
                                </Typography>
                            </Paper>

                        </Grid>
                    </Grid>

                    {/* Dhuhr */ }
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Dhuhr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Dhuhr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.dhuhr' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.dhuhr' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Dhuhr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Dhuhr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 2 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 2 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Dhuhr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Dhuhr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 2 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 2 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Asr */ }
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Asr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Asr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.asr' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.asr' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Asr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Asr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 3 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 3 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Asr ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Asr ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 3 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 3 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Maghrib */ }
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Maghrib ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Maghrib ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.maghrib' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.maghrib' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Maghrib ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Maghrib ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 4 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 4 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Maghrib ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Maghrib ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 4 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 4 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Isha */ }
                    <Grid container spacing={ 2 } sx={ { height: '100%' } }>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Isha ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Isha ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( t( 'salat.isha' ) ) ? 'arabic' : '' }
                                >
                                    { t( 'salat.isha' ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Isha ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Isha ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 5 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, adhanTimes()[ 5 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={ 4 }>
                            <Paper
                                id="xmoxshadow"
                                sx={ {
                                    padding: 2,
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '1.5vh',
                                } }
                                className={ state.Isha ? 'moonode_heighlight_blue' : '' }
                            >
                                <Typography
                                    sx={ {
                                        color: state.Isha ? '#fff' : 'none',

                                    } }
                                    variant="h3"
                                    className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 5 ]?.time ) ) ? 'arabic' : '' }
                                >
                                    { convertTimeWorld( tvSettings?.isTimeConvention, iqamaTimes()[ 5 ]?.time ) }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Jumua */ }
                    { jumuaTimes.map( ( jumua, index ) => (
                        <Grid container spacing={ 2 } sx={ { height: '100%' } } key={ index }>
                            <Grid item xs={ 12 }>
                                <Paper
                                    id="xmoxshadow"
                                    sx={ {
                                        padding: 2,
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center', // This centers content horizontally
                                        borderRadius: '1.5vh',
                                    } }
                                >
                                    <Typography
                                        variant="h3"
                                        sx={ {
                                            textAlign: 'center',
                                            mr: 10

                                        } }
                                        className={ isArabic( t( 'salat.jumua' ) ) ? 'arabic' : '' }
                                    >
                                        { t( 'salat.jumua' ) } { index + 1 } { " " }
                                    </Typography>
                                    <Typography
                                        sx={ {
                                            textAlign: 'center', // Ensure text is centered

                                        } }
                                        variant="h2"
                                        className={ isArabic( convertTimeWorld( tvSettings?.isTimeConvention, jumua.time ) ) ? 'arabic' : '' }
                                    >
                                        { convertTimeWorld( tvSettings?.isTimeConvention, jumua.time ) || 'N/A' }
                                    </Typography>
                                </Paper>

                            </Grid>
                        </Grid>
                    ) ) }

                    { children }
                </Box>
            </Box>
        )
    );
};

export default MobilePrayer;
