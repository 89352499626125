import React, { useEffect, useState } from "react";
import { adhanTime, iqamaTime, reloadMidnight, tvNotificationTime } from "../../../../services/formatDate.js";
import Ads from "../../../../layouts/prayerTV/TopLeft&Righ/Ads.js";
import { format } from "date-fns";
import Jumua from "../../../../layouts/prayerTV/TopLeft&Righ/Jumua.js";
import Next from "../../../../layouts/prayerTV/TopLeft&Righ/Next.js";
import Eid from '../../../../layouts/eid/index.js'

const Index = ( {
  tvPrayer,
  arabic,
  whatIsNext,
  setIqama,
  setAdhan,
  setTvNotification,
  adhan,
  iqama,
  switchLangue,
  turningOffAds,
  langs,
  manualInterval,
  color,
  textColor,
  reload,
  isArabicFont
} ) => {

  const [ time, setTime ] = useState( "" );
  const [ date, setDate ] = useState( "" );
  const [ manualTime, setManualTime ] = useState( manualInterval ? manualInterval * ( 1000 ) : 90000 );

  useEffect( () => {
    try {
      let isTvNotification;
      setDate( format( new Date(), "dd MMM yyyy" ) );
      const interval = setInterval( () => {
        setTime( format( new Date(), `${ tvPrayer.tvSettings.isTimeConvention ? "h" : "HH" }:mm:ss` ) );
        if ( adhanTime( tvPrayer.apiPrayerTime ) ) {
          if ( !adhan ) {
            setAdhan( true );
          }
        }
        if ( iqamaTime( tvPrayer.iqamaTime ) ) {
          if ( !iqama ) {
            setIqama( true );
          }
        }
        if ( ( isTvNotification = tvNotificationTime( tvPrayer.apiPrayerTime ) ) > 0 ) {

          if ( !adhan ) {

            setTvNotification( true, isTvNotification );
          }
        }
        if ( reload ) {
          reloadMidnight()
        }
      }, 1000 );

      const intervalLang = setInterval( () => {
        switchLangue();
      }, 14000 );

      const intervalAds = setInterval( () => turningOffAds( true ), manualTime );

      return () => {
        clearInterval( interval );
        clearInterval( intervalLang );
        clearInterval( intervalAds );
      };
    } catch ( error ) {
      console.log( error );
    }

  }, [] );


  const returnDate = ( tvPrayer ) => {
    let settings = ( tvPrayer.hijriPreference === "(null)" || tvPrayer.hijriPreference === null ) ? "0" : tvPrayer.hijriPreference;
    let date = " ";
    try {
      let hijriPref = Number( settings ) + 2;
      date = tvPrayer.apiPrayerTime.HijriDates.hijriEn[ hijriPref ].date
      return date;
    } catch ( error ) {
      return date;
    }
  }

  return (
    < >
      <Ads isArabicFont={ isArabicFont } tvPrayer={ tvPrayer } arabic={ arabic } langs={ langs } color={ color } fontWeight={ 400 } />
      <div className="middle-container " style={ { marginTop: '3.5vh', } }>
        <div className="middle-box-1" id="xmoxshadow" >
          <h1 style={ { fontSize: "10vw", textAlign: "center", maxHeight: "320px", color: "#000", fontWeight: 400, marginTop: -10, marginBottom: '2vw' } } > { time } </h1>
          <h1 style={ { fontSize: "6vh", textAlign: "center", color: "#000", fontWeight: 400, marginTop: '-2vw' } } > { arabic ? returnDate( tvPrayer ) : date }
          </h1>
          <Next isArabicFont={ isArabicFont } tvPrayer={ tvPrayer } whatIsNext={ whatIsNext } arabic={ arabic } langs={ langs } color={ color } fontWeight={ 400 } />
        </div>
        <Jumua isArabicFont={ isArabicFont } arabic={ arabic } tvPrayer={ tvPrayer } langs={ langs } color={ color } textColor={ textColor } fontWeight={ 400 } isTimeConvention={ tvPrayer.tvSettings.isTimeConvention } />
        { tvPrayer.prayerTime.toggleEid ? <Eid tvPrayer={ tvPrayer } arabic={ arabic } langs={ langs } isArabicFont={ isArabicFont } /> :
          <div className="middle-box-2" style={ { backgroundColor: "transparent" } } id="xmoxshadow">
            <img className="middle-box-2" src={ tvPrayer.entity.logo || tvPrayer.logo } style={ { width: "100%", height: "100%", borderRadius: "2vh", backgroundColor: "#fff", objectFit: 'fill' } } />
          </div>
        }
      </div>
    </>
  );
};

export default Index;
