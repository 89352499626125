import React, { PureComponent, Suspense, lazy } from 'react';
import { getSocket, initializeSocket } from '../../../services/socket';
import { SocketContext } from '../../../context/socket';
import { useToken } from '../../../context/tokenContext';
import withParamsAndOnline from '../../wrappers/hoc/withParams';

const LazyParkingAlert = lazy( () => import( '../../app/features/parkingAlert' ) );

class CombinedSocketWrapper extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            isConnected: false,
            isSocketInitialized: false,
            fooEvents: [],
            parkingAlert: null,
            timeLeft: 0, // 300 seconds for 5 minutes
        };
    }

    async componentDidMount () {
        const { token } = this.props;
        if ( token ) {
            await this.initializeSocket( token );
        }
        this.addVisibilityListener();
    }

    async componentDidUpdate ( prevProps ) {
        if ( prevProps.token !== this.props.token ) {
            this.cleanupSocket();
            await this.initializeSocket( this.props.token );
        }

        if ( prevProps.online !== this.props.online && this.props.online ) {
            await this.initializeSocket( this.props.token );
        }
    }

    componentWillUnmount () {
        this.cleanupSocket();
        if ( this.timerId ) {
            clearInterval( this.timerId );
        }
        this.removeVisibilityListener();
    }

    initializeSocket = async ( token ) => {
        if ( token ) {
            await initializeSocket( token );
            this.setupSocketListeners();
            this.setState( { isSocketInitialized: true } );
        }
    };

    cleanupSocket = () => {
        if ( this.state.isSocketInitialized ) {
            const socket = getSocket();
            socket.off( 'connect', this.onConnect );
            socket.off( 'disconnect', this.onDisconnect );
            socket.off( 'reconnect', this.onReconnect );
            socket.off( 'foo', this.onFooEvent );
            socket.off( 'change', this.onChangeEvent );
            socket.off( 'parkingAlert', this.onParkingAlertEvent );
            socket.off( 'connect_error', this.onErrorEvent );
            socket.off( 'error', this.onErrorEvent );

            // Close the WebSocket connection explicitly to support bfcache
            if ( socket && socket.connected ) {
                socket.close();
            }

            this.setState( { isSocketInitialized: false } );
        }
    };

    setupSocketListeners = () => {
        const socket = getSocket();
        socket.on( 'connect', this.onConnect );
        socket.on( 'disconnect', this.onDisconnect );
        socket.on( 'reconnect', this.onReconnect );
        socket.on( 'foo', this.onFooEvent );
        socket.on( 'change', this.onChangeEvent );
        socket.on( 'parkingAlert', this.onParkingAlertEvent );
        socket.on( 'connect_error', this.onErrorEvent );
        socket.on( 'error', this.onErrorEvent );
    };

    startTimer = () => {
        if ( this.state.timeLeft > 0 ) {
            this.timerId = setInterval( () => {
                this.setState( ( prevState ) => {
                    if ( prevState.timeLeft > 1 ) {
                        return { timeLeft: prevState.timeLeft - 1 };
                    } else {
                        clearInterval( this.timerId );
                        return { timeLeft: 0, parkingAlert: null };
                    }
                } );
            }, 1000 );
        }
    };

    onConnect = () => {
        this.setState( { isConnected: true } );
        const { dinScreenId } = this.props;
        getSocket().emit( 'joinRoom', dinScreenId );
    };

    onDisconnect = () => {
        this.setState( { isConnected: false } );
    };

    onReconnect = () => {
        this.setState( { isConnected: true } );
    };

    onFooEvent = ( value ) => {
        this.setState( ( prevState ) => ( {
            fooEvents: [ ...prevState.fooEvents, value ],
        } ) );
    };

    onChangeEvent = ( { update } ) => {
        console.log( 'Change event received:', update );
        if ( update ) {
            this.props.refetchData();
        }
    };

    onParkingAlertEvent = ( { parkingAlert } ) => {
        this.setState( { parkingAlert, timeLeft: 300 }, () => {
            this.startTimer();
        } );
    };

    onErrorEvent = ( error ) => {
        console.error( 'Socket error:', error );
    };

    handleVisibilityChange = async () => {
        if ( document.visibilityState === 'visible' && !this.state.isConnected ) {
            await this.initializeSocket( this.props.token );
        }
    };

    addVisibilityListener = () => {
        document.addEventListener( 'visibilitychange', this.handleVisibilityChange );
    };

    removeVisibilityListener = () => {
        document.removeEventListener( 'visibilitychange', this.handleVisibilityChange );
    };

    render () {
        const { children } = this.props;
        const { timeLeft, isConnected, isSocketInitialized, parkingAlert } = this.state;

        // if ( !isSocketInitialized ) {
        //     return <>...</>;
        // }

        return (
            <SocketContext.Provider value={ { isConnected, socket: getSocket() } }>
                { parkingAlert ? (
                    <Suspense fallback={ null }>
                        <LazyParkingAlert parkingAlert={ parkingAlert } timeLeft={ timeLeft } />
                    </Suspense>
                ) : (
                    children
                ) }
            </SocketContext.Provider>
        );
    }
}

const CombinedSocketWrapperWithToken = ( props ) => {
    const { token, refetchData, dinScreenId } = useToken();
    return <CombinedSocketWrapper { ...props } token={ token } refetchData={ refetchData } dinScreenId={ dinScreenId } />;
};

export default withParamsAndOnline( React.memo( CombinedSocketWrapperWithToken ) );
