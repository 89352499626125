import React from 'react';
import { Box } from '@mui/material';
import GlassedBox from '../../../../layouts/glassmorphism/boxGlassed';
import nophone from '../../../../styles/img/nophone.webp';

const ClosePhone = () => {

    const glassedBoxStyles = {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
        margin: '.5vh',
        height: '94%',
        width: '100%',
    };

    const innerBoxStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    };

    const imageStyles = {
        marginTop: '10vh',
        width: '120vh',
        height: 'auto',
        objectFit: 'contain',
    };

    return (
        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles }>
            <Box sx={ innerBoxStyles }>
                <img
                    src={ nophone }
                    alt="Close Phone"
                    style={ imageStyles }
                    loading="lazy"
                />
            </Box>
        </GlassedBox>
    );
};

export default React.memo( ClosePhone );
