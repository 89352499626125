import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
import PlayListController from '../../posts/revised';
import { NEXT_MEDIA } from '../../../actions/types';
import {
    getPlaying,
    getTypeAudio,
    getActualPost,
} from '../../../services/selectors/playerSelectors';
import { togglePlay } from '../../../actions/player';

const Player = () => {
    const dispatch = useDispatch();
    const playing = useSelector( getPlaying );
    const typeAudio = useSelector( getTypeAudio );

    const { actualPost, parentIsThread } = useSelector( getActualPost );

    const handleTimeout = useCallback( () => {

        if ( typeAudio ) {
            return;
        }
        dispatch( { type: NEXT_MEDIA } );

        if ( !parentIsThread ) {
            dispatch( togglePlay( false ) );
        }


    }, [ dispatch, parentIsThread ] );

    useEffect( () => {
        if ( !actualPost ) return;
        let duration;
        // Default unit is seconds if not provided
        if ( actualPost?.unit === 'mins' ) {
            duration = actualPost?.duration && actualPost.duration > 0 ? actualPost.duration * 60000 : 15000;
        } else {
            duration = actualPost?.duration && actualPost.duration > 0 ? actualPost.duration * 1000 : 15000;
        }
        const timer = setTimeout( handleTimeout, duration );
        return () => clearTimeout( timer );
    }, [ actualPost, handleTimeout ] );
    return (
        playing || ( typeAudio && actualPost ) ? <div style={ { width: '100%', height: '100%', display: 'flex' } }> <PlayListController key={ actualPost ? actualPost.id : 'nopost' } actualPost={ actualPost } /> </div> : <p>No media loaded</p>
    );
};

export default React.memo( Player );
