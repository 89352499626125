import React, { Fragment, Component, lazy, Suspense } from 'react';

import { getPlaylistLength } from '../../../../services/selectors/playerSelectors'; // Import the selector
import { togglePlay } from '../../../../actions/player';
import { postPrayer } from "../../../../actions/prayer";
import PostTv from '../../../wrappers/player/index';
import TvUi from '../../../pages/blue';
import { connect } from "react-redux";
const FooterDown = lazy( () => import( '../../../../layouts/footer' ) );

class Television extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            tvInfo: [],
            isAds: false,
            isOk: true,
            tvSettings: null,
            fullScreenMode: false,
            arabic: true,
            adhan: false,
            iqama: false,
            intervalFetch: null,
            intervalPlayer: null,
            intervalCancelAds: null,
            intervalStartAds: null,
            tvAdsActivated: false,
            isTvNotification: false,
            tvNotification: 0,
        };
        this.turningOffAds = this.turningOffAds.bind( this );
        this.handleFullscreen = this.handleFullscreen.bind( this );
    }

    turningOffAds = ( bool ) => {
        this.setState( { ...this.state, isAds: bool } );
    }

    switchLangue = () => {
        this.setState( { ...this.state, arabic: !this.state.arabic } );
    }

    setAdhan = ( bool ) => {
        this.setState( { ...this.state, adhan: bool } );
    }

    setIqama = ( bool ) => {
        this.setState( { ...this.state, iqama: bool } );
    }

    handleFullscreen = () => {
        try {
            if ( !document.fullscreenElement ) {
                if ( document.documentElement.requestFullscreen ) {
                    document.documentElement.requestFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to enable fullscreen: ${ err.message }` );
                    } );
                }
            } else {
                if ( document.exitFullscreen ) {
                    document.exitFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to exit fullscreen: ${ err.message }` );
                    } );
                }
            }
        } catch ( error ) {
            console.error( "An error occurred while trying to switch fullscreen mode:", error );
        }
    }

    setTvNotification = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool
        } ) );
    }

    setTvNotificationAndIqama = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool,
            iqama: bool,
        } ) );
    }

    componentDidMount () {
        try {
            const { token, playlistLength, dispatch, code, online } = this.props;

            this.props.postPrayer( token, online, code );

            const intervalFetch = setInterval(
                () => this.props.postPrayer( token, online, code ),
                1200000
            );

            let intervalPlayer = null;

            if ( playlistLength > 0 ) {
                intervalPlayer = setInterval(
                    () => dispatch( togglePlay( true ) ),
                    180000 // 3 mins
                );
            }

            this.setState( { intervalFetch, intervalPlayer } );

        } catch ( error ) {
            console.error( "An error occurred in componentDidMount:", error );
        }
    }

    componentWillUnmount () {
        clearInterval( this.state.intervalFetch );
        if ( this.state.intervalPlayer ) {
            clearInterval( this.state.intervalPlayer );
        }
    }

    render () {
        const { playing } = this.props;
        return (
            <Fragment>
                <div onClick={ () => this.handleFullscreen() }>
                    { playing ? <div
                        style={ {
                            width: '100%',
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column'
                        } }
                    >
                        <PostTv />
                    </div > : <TvUi >
                        <Suspense fallback={ <div>Loading...</div> }>
                            <FooterDown dinscreen={ false } screenName="2024 September 25 version." white={ true } />
                        </Suspense>
                    </TvUi>
                    }
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ( {
    admin: state.admin,
    playlistLength: getPlaylistLength( state ), // map playlistLength from state
} );

export default connect( mapStateToProps, { postPrayer } )( Television );
