import React from 'react';
import Flyer from '../../views/wrappers/flyer';
import { useParams } from 'react-router-dom';
import Screen from '../../views/pages/flyer'
import Footer from '../../layouts/footer';


const View = ( { ads = true } ) => {
    const { digits } = useParams();
    const urlParams = new URLSearchParams( window?.location?.search );
    const code = urlParams?.get( 'code' );
    return <Flyer digits={ digits } code={ code } >
        <Screen >
            <Footer dinscreen={ false } screenName='FlyerScreen' />
        </Screen>
    </Flyer>;
};

export default View;
