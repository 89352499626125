import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import usePrayerTimeState from '../../../../../../hooks/prayerState';
import { convertTimeWorld } from '../../../../../../services/formatDate';
import GlassedBox from '../../../../../../layouts/glassmorphism/boxGlassed';
import TextFadeComponent from '../../../../../../layouts/TitleComponent';

import {
    getIqamaTime,
    getTvSettings,
    getHighlighted,
    getTomorrowIqamaTime,
} from '../../../../../../services/selectors/prayersSelectors';


const SalatIqamaBox = () => {

    const theme = useTheme();
    const { t } = useTranslation();
    const iqamaTime = useSelector( getIqamaTime );
    const tvSettings = useSelector( getTvSettings );
    const highlighted = useSelector( getHighlighted );
    const tomorrowIqamaTime = useSelector( getTomorrowIqamaTime );
    const state = usePrayerTimeState( iqamaTime, tomorrowIqamaTime );
    const dataAvailable = tomorrowIqamaTime && iqamaTime;

    const { f, d, a, m, i } = highlighted;

    const renderPrayerTime = useCallback(
        ( prayer, diff, nextTime ) =>
            dataAvailable ? (
                diff ? convertTimeWorld( tvSettings.isTimeConvention, nextTime ) : convertTimeWorld( tvSettings.isTimeConvention, prayer )
            ) : (
                null
            ),
        [ dataAvailable, tvSettings ]
    );

    const getPrayerTimes = useCallback( () => [
        { key: 'Isha', state: state.Isha, diff: state.iDiff && i, time: iqamaTime?.Isha, nextTime: tomorrowIqamaTime?.Isha },
        { key: 'Maghrib', state: state.Maghrib, diff: state.mDiff && m, time: iqamaTime?.Maghrib, nextTime: tomorrowIqamaTime?.Maghrib },
        { key: 'Asr', state: state.Asr, diff: state.aDiff && a, time: iqamaTime?.Asr, nextTime: tomorrowIqamaTime?.Asr },
        { key: 'Dhuhr', state: state.Dhuhr, diff: state.dDiff && d, time: iqamaTime?.Dhuhr, nextTime: tomorrowIqamaTime?.Dhuhr },
        { key: 'Fajr', state: state.Fajr, diff: state.fDiff && f, time: iqamaTime?.Fajr, nextTime: tomorrowIqamaTime?.Fajr },
    ], [ state, iqamaTime, tomorrowIqamaTime, i, m, a, d, f ] );

    const prayerTimes = getPrayerTimes();

    const boxStyles = {
        height: '40%',
        display: 'flex',
        borderRadius: '2vh',
    };

    const glassedBoxStyles = ( isActive, diff ) => ( {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        backgroundColor: isActive ? '#fff' : '',
        ...( isActive ? { gmode: 'gm-xmode321s', marginTop: '0.5vh' } : ( diff ? { gmode: 'gm-lmod5431s' } : '' ) ),
    } );

    const typographyStyles = {
        fontSize: theme.palette.salatFont,
    };

    return (
        <Box sx={ boxStyles }>
            { prayerTimes?.map( ( prayer ) => (
                <GlassedBox
                    key={ prayer.key }
                    gmode={ glassedBoxStyles( prayer.state, prayer.diff ).gmode }
                    styles={ glassedBoxStyles( prayer.state, prayer.diff ) }
                >
                    <Typography className="tltxa" sx={ typographyStyles }>
                        { renderPrayerTime( prayer.time, prayer.diff, prayer.nextTime ) }
                    </Typography>
                </GlassedBox>
            ) ) }
            <GlassedBox styles={ { flex: 1 } }>
                <TextFadeComponent text={ t( 'salat.iqama' ) } textStyle={ { fontSize: '3.5vw', marginTop: "2.5vh" } } />
            </GlassedBox>
        </Box>
    );
};

export default React.memo( SalatIqamaBox );
