import React, { Fragment } from "react";
import logo from '../../styles/img/iconAndroid.png';
import "./effect.min.css";
const AdhanText = ( { isAdhan } ) => {
    function formatText ( text, charsPerLine ) {
        text = text.replace( /-/g, '&#8209;' );
        let formattedText = '';
        let currentLineCharCount = 0;
        let i = 0;
        let characters = Array.from( text );
        for ( let char of characters ) {
            if ( currentLineCharCount >= charsPerLine || ( i > 4 && currentLineCharCount === 4 ) ) {
                formattedText += '\n';
                currentLineCharCount = 0;
            }
            formattedText += char;
            currentLineCharCount++;
            i++;
        }
        return formattedText;
    }
    return (
        <Fragment>
            <div className="effects" style={ { zIndex: '199' } }>
                <div className="effect-holder">
                    <div className="dropdown-style">
                        <small style={ { fontSize: '3vw', color: '#FFF' } }>
                            { formatText( isAdhan ? "ADHAN" : "IQAMA", 7 ) }
                        </small>
                    </div>
                </div>
                <div className="effect-holder">
                    <div className="dropdown-style logo-holder">
                        <img
                            src={ logo }
                            style={ { height: "60%", width: "60%" } }
                            alt="Logo"
                        />
                    </div>
                </div>
                <div className="effect-holder">
                    <div className="dropdown-style">
                        <small style={ { fontSize: '5vw', color: '#FFF' } }>
                            { formatText( isAdhan ? "الأذان" : "الإقامة", 7 ) }
                        </small>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdhanText;
