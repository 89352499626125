import React from 'react';
import { useParams } from 'react-router-dom';
import Tv from '../../../views/wrappers/blue/mobile';
import { useSelector, useDispatch } from 'react-redux';
import useNavigatorOnLine from '../../../hooks/onlineStatus';
import { getPlaying } from '../../../services/selectors/playerSelectors';

const View = ( { ads = true } ) => {
    const { token } = useParams();
    const online = useNavigatorOnLine();
    const urlParams = new URLSearchParams( window?.location?.search );
    const code = urlParams?.get( 'code' );
    const dispatch = useDispatch();
    const playing = useSelector( getPlaying );

    return <Tv token={ token } online={ online } code={ code } ads={ ads } dispatch={ dispatch } playing={ playing } />;
};

export default View;
