import React from "react";

const Images = ( { post, dimensions } ) => {
  return (
    <img
      id="myImage_TV"
      alt="displayed content"
      src={ post?.media[ 0 ]?.url }
      style={ {
        ...dimensions,
        objectFit: 'cover',
        width: '100%', // Ensure it takes full width
        height: '100%', // Ensure it takes full height
      } }
    />
  );
};

export default React.memo( Images );
