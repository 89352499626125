import { getPayloadFromLocalStorage, setAndProcessPhone } from '../services/app/mapping';
// import { structureData } from '../services/app/prayers/processing'
import Purify from 'moonode-salat-time-purify';

import { getPrayerTiming, postPrayerTiming } from '../services/app/prayers/api'
import { changeLanguage } from '../actions/language';
import { isAfterMaghrib } from '../services/app/prayers/states';
import i18n from 'i18next';

import {
    POST_SALAT,
    GET_PRAYERS,
    LOAD_PLAYLIST,
    CLEAR_PLAYLIST,
    SET_DARK_MODE_APP_INIT,
} from "./types";

const { structureData } = Purify;

export const getPrayers = ( phoneNum, online, fallbackHadiths, code ) => async dispatch => {


    try {
        let data;
        if ( online ) {

            // to do getPrayerTiming
            data = await getPrayerTiming( phoneNum, code );
            await setAndProcessPhone( phoneNum, data );

        }
        if ( !data ) {
            const payload = await getPayloadFromLocalStorage( phoneNum );
            if ( payload ) {
                const parsedPayload = JSON.parse( payload );
                data = parsedPayload;
            }
        }

        if ( data ) {
            let structuredData = await structureData( data?.data, online );
            dispatch( {
                type: GET_PRAYERS,
                payload: structuredData
            } );
            dispatch( {
                type: SET_DARK_MODE_APP_INIT,
                payload: structuredData.darkModeStting
            } );


            dispatch( loadPlaylist( structuredData, fallbackHadiths ) );
        }
        return true
    } catch ( error ) {
        console.log( 'be more specific' );
    }
};

export const postPrayer = ( extId, online, code ) => async dispatch => {
    try {
        let data;
        if ( online ) {
            // to do getPrayerTiming
            data = await postPrayerTiming( extId, code );
            if ( data ) {
                await setAndProcessPhone( extId, data );
            }

        }
        if ( !data ) {
            const payload = await getPayloadFromLocalStorage( extId );
            if ( payload ) {
                const parsedPayload = JSON.parse( payload );
                data = parsedPayload;
            }
        }
        if ( data ) {
            let structuredData = await structureData( data?.data, true );

            dispatch( {
                type: POST_SALAT,
                payload: structuredData
            } );
            dispatch( initializeState( structuredData ) );
        }
        const token = data?.data?.token;
        return token
    } catch ( error ) {
        console.log( 'be more specific', error );
        return false
    }
};

export const initializeState = ( structuredData ) => async dispatch => {
    try {
        const langs = [ 'fr', 'ar', 'en', 'ur', 'bn', 'ja' ];
        const newLang = langs[ Number( structuredData.tvLangsOne ) ];
        dispatch( changeLanguage( newLang ) );
        i18n.changeLanguage( newLang );
        let darkModeSetting = structuredData?.darkModeStting;
        if ( darkModeSetting === 'auto' ) {
            const maghribTime = structuredData?.apiPrayerTime?.Maghrib;
            darkModeSetting = isAfterMaghrib( maghribTime ) ? 'true' : 'false';
        }

        dispatch( {
            type: SET_DARK_MODE_APP_INIT,
            payload: darkModeSetting === 'true',
        } );
        dispatch( loadPlaylist( structuredData ) );
    } catch ( e ) {
        console.log( 'hellow its me', e );

    }
};

export const loadPlaylist = ( structuredData ) => ( dispatch, getState ) => {
    if (
        structuredData &&
        structuredData.playList &&
        typeof structuredData.playList === 'object' &&
        Array.isArray( structuredData.playList?.posts ) &&
        structuredData.playList.posts.length > 0
    ) {
        dispatch( {
            type: LOAD_PLAYLIST,
            payload: structuredData.playList
        } );
    } else {
        dispatch( {
            type: CLEAR_PLAYLIST,
        } );
    }
};



export const postPrayerLocalStorage = ( extId, online = false ) => async dispatch => {


    try {

        let data = null;

        const payload = await getPayloadFromLocalStorage( extId );
        if ( payload ) {
            const parsedPayload = JSON.parse( payload );
            data = parsedPayload;
        }
        if ( data ) {

            let structuredData = await structureData( data?.data, online );

            dispatch( {
                type: POST_SALAT,
                payload: structuredData
            } );
        }
        return true
    } catch ( error ) {
        console.log( 'be more specific' );
    }
};