import React, { Component, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import iqamaImage from '../../../../styles/img/notifications/aqsa.png';
import adhanImage from '../../../../styles/img/notifications/kaaba.png';
import shuruqImage from '../../../../styles/img/notifications/sunriseen-original.png';
import midnightImage from '../../../../styles/img/notifications/midnighten-original.png';
import thirdNightImage from '../../../../styles/img/notifications/thirdnighten-original.png';

import {
    getIsAdhan,
    getIsIqama,
    getIsShuruq,
    getIsThirdNight,
    getIsMiddleNight,
} from '../../../../services/selectors/appStatusSelectors';
import {
    getAdhanAudioMode,

} from '../../../../services/selectors/prayersSelectors';

const Marquee = lazy( () => import( '../../../../layouts/marquee' ) );
const GlassedBox = lazy( () => import( '../../../../layouts/glassmorphism/boxGlassed' ) );

const outerBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1.5vh',
    marginLeft: '1.5vh',
    marginBottom: '0.5vw',
    backgroundColor: 'transparent',
    height: '80%',
};

const glassedBoxStyles1 = {
    width: '100%',
    borderRadius: '2vh',
    margin: '0.3vh',
    marginRight: '0.2vh',
};

const glassedBoxStyles2 = {
    height: '14%',
    marginTop: '-.2%',
    marginRight: '1.5vh',
    marginLeft: '1.5vh',
};

const imageStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '2vh',
    objectFit: 'contain',
};

class SpecialImageDisplay extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            announcement: {
                isAudio: false,
                audioImage: '',
                image: null,
                message: '',
                altText: '',
                adhan: '',
            },
        };
    }

    componentDidMount () {

        this.updateAnnouncement();
    }

    componentDidUpdate ( prevProps ) {
        if (
            prevProps.isAdhan !== this.props.isAdhan ||
            prevProps.isIqama !== this.props.isIqama ||
            prevProps.isThirdNight !== this.props.isThirdNight ||
            prevProps.isMiddleNight !== this.props.isMiddleNight ||
            prevProps.isShuruq !== this.props.isShuruq

        ) {
            this.updateAnnouncement();
        }
    }

    updateAnnouncement = () => {
        const { t, isAdhan, isIqama, isThirdNight, isMiddleNight, isShuruq, adhanAudioMode } = this.props;
        let newAnnouncement = {
            isAudio: false,
            audioImage: '',
            image: null,
            message: '',
            altText: '',
            adhan: '',
        };

        if ( isAdhan.status ) {

            const adhanAudio = adhanAudioMode[ isAdhan.name ] || false;

            newAnnouncement = {
                message: `${ t( 'notification.adhan' ) }`,
                adhan: adhanAudioMode.adhan,
                audioImage: adhanImage,
                isAudio: adhanAudio,
                image: adhanImage,
                altText: 'Adhan',
            };

        } else if ( isIqama.status ) {
            newAnnouncement = {
                message: t( 'notification.iqama' ),
                image: iqamaImage,
                altText: 'Iqama',
                isAudio: false,

            };
        } else if ( isThirdNight ) {
            newAnnouncement = {
                message: t( 'notification.thirdNight' ),
                image: thirdNightImage,
                altText: 'Third Night',
                isAudio: false,

            };
        } else if ( isMiddleNight ) {
            newAnnouncement = {
                message: t( 'notification.middleNight' ),
                image: midnightImage,
                altText: 'Midnight',
                isAudio: false,

            };
        } else if ( isShuruq ) {
            newAnnouncement = {
                message: t( 'notification.shuruq' ),
                image: shuruqImage,
                altText: 'Shuruq',
                isAudio: false,

            };
        }

        this.setState( { announcement: newAnnouncement } );
    };

    render () {
        const { children } = this.props;
        const { announcement } = this.state;

        if ( !announcement?.message ) {
            return <>{ children }</>;
        } else {
            return (
                <>
                    <Suspense fallback={ <></> }>
                        <Box sx={ outerBoxStyles }>
                            <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles1 }>
                                <img src={ announcement.image } alt={ announcement.altText } style={ imageStyles } />
                            </GlassedBox>
                        </Box>
                        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles2 }>
                            <Marquee announcement={ announcement?.message } isAudio={ announcement?.isAudio } audio={ announcement?.adhan } image={ announcement?.audioImage } isAdhan={ true } duration="20s" />
                        </GlassedBox>
                    </Suspense>
                </>
            );
        }
    }
}

const mapStateToProps = ( state ) => ( {
    isIqama: getIsIqama( state ),
    isAdhan: getIsAdhan( state ),
    isShuruq: getIsShuruq( state ),
    isThirdNight: getIsThirdNight( state ),
    isMiddleNight: getIsMiddleNight( state ),
    adhanAudioMode: getAdhanAudioMode( state ),
} );

export default connect( mapStateToProps )( withTranslation()( SpecialImageDisplay ) );
