import React, { PureComponent } from 'react';
import GlassedBox from '../../layouts/glassmorphism/boxGlassed';
import { Typography, Box } from '@mui/material';
import Video from '../../layouts/video/';
import TextDisplay from './media/text';
import { connect } from 'react-redux';
import Images from './media/images';
import {
    getTypeVideo,
    getTypeText,
    getTypeImage
} from '../../services/selectors/playerSelectors';

class PlayListController extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            dimensions: this.getWindowDimensions( 0.5, 1.25 )
        };
    }
    componentDidMount () {
        window.addEventListener( 'resize', this.updateWindowDimensions );
    }
    componentWillUnmount () {
        window.removeEventListener( 'resize', this.updateWindowDimensions );
    }
    getWindowDimensions = ( customWidth = 1.44, customHeight = 1.15 ) => {
        return {
            width: window.innerWidth / customWidth,
            height: window.innerHeight / customHeight
        };
    };

    updateWindowDimensions = () => {
        this.setState( { dimensions: this.getWindowDimensions( 1.44, 1.15 ) } );
    };

    renderMedia () {

        const { actualPost, typeImage, typeVideo, typeText } = this.props;
        const { dimensions } = this.state;

        if ( !actualPost || !actualPost.media ) {
            return <Typography sx={ { fontSize: "2vh", textAlign: "center", m: 2 } }>No media loaded</Typography>;
        }
        if ( typeImage ) {
            return <Images post={ actualPost } dimensions={ dimensions } />;
        } else if ( typeVideo ) {
            return <Video videoUrl={ actualPost.isLink ? actualPost.link : actualPost.media[ 0 ]?.url } />;
        } else if ( typeText ) {
            return <TextDisplay text={ actualPost?.text } />;
        } else {
            return <Typography sx={ { fontSize: "2vh", textAlign: "center", m: 2 } }>No media loaded</Typography>;
        }
    }

    render () {
        return (
            <GlassedBox
                styles={ {
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                } }
            >
                { this.renderMedia() }
            </GlassedBox>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    typeVideo: getTypeVideo( state ),
    typeText: getTypeText( state ),
    typeImage: getTypeImage( state ),
} );

export default connect( mapStateToProps )( PlayListController );
