import React from 'react';
import { useParams } from 'react-router-dom';
import useNavigatorOnLine from '../../../hooks/onlineStatus';

const withParamsAndOnline = ( Component ) => {
    return ( props ) => {

        const params = useParams();
        const online = useNavigatorOnLine();
        const urlParams = new URLSearchParams( window?.location?.search );
        const code = urlParams?.get( 'code' );
        return <Component { ...props } params={ params } online={ online } code={ code } />;
    };
};

export default withParamsAndOnline;
