import React, { Fragment, Component } from 'react'
import { connect } from "react-redux";
import TvUi from '../../pages/modern/Index';
import { getPrayerEntityTv, somePostsManagments } from "../../../actions/admin";
import PostTv from '../../posts';
import { DATA } from '../../../services/Lang.js';
import '../../../styles/sultan.min.css';


class Television extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            tvInfo: [],
            isAds: false,
            isOk: true,
            tvSettings: null,
            fullScreenMode: false,
            arabic: true,
            adhan: false,
            iqama: false,
            // interval: 5000,
            intervalFetch: null,
            intervalCancelAds: null,
            intervalStartAds: null,
            tvAdsActivated: false,
            isTvNotification: false,
            tvNotification: 0,
        }
        this.turningOffAds = this.turningOffAds.bind( this );
        this.handleFullscreen = this.handleFullscreen.bind( this );
    }



    turningOffAds = ( bool ) => {
        this.setState( { ...this.state, isAds: bool } )
    }
    switchLangue = () => {
        this.setState( { ...this.state, arabic: !this.state.arabic } )
    }
    //added
    setAdhan = ( bool ) => {
        this.setState( { ...this.state, adhan: bool } );
    }
    //added
    setIqama = ( bool ) => {
        this.setState( { ...this.state, iqama: bool } );
    }
    handleFullscreen = () => {
        try {
            if ( !document.fullscreenElement ) {
                if ( document.documentElement.requestFullscreen ) {
                    document.documentElement.requestFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to enable fullscreen: ${ err.message }` );
                    } );
                }
            } else {
                if ( document.exitFullscreen ) {
                    document.exitFullscreen().catch( ( err ) => {
                        console.warn( `Error attempting to exit fullscreen: ${ err.message }` );
                    } );
                }
            }
        } catch ( error ) {
            console.error( "An error occurred while trying to switch fullscreen mode:", error );
        }
    }

    setTvNotification = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool
        } ) );
    }
    setTvNotificationAndIqama = ( bool, tvNot ) => {
        this.setState( prevState => ( {
            ...prevState,
            isTvNotification: bool,
            tvNotification: tvNot,
            adhan: bool,
            iqama: bool,
        } ) );
    }

    componentDidMount () {

        try {

            const { token } = this.props;
            this.props.getPrayerEntityTv( token )

            const intervalFetch = setInterval(
                () => this.props.getPrayerEntityTv( token ),
                1200000,
            );

            this.setState( { intervalFetch: intervalFetch } )


        } catch ( error ) {
            console.log( error );
        }
    }


    componentWillUnmount () {
        clearInterval( this.state.intervalFetch )

    }

    render () {
        const { adminLoading, tvPrayer, somePosts, tvSettings } = this.props.admin;
        const { token, ads, time, reaload } = this.props;
        return (
            adminLoading ? <div style={ {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '20px'
            } }>
                Loading...
            </div> : <Fragment>
                <div onClick={ () => this.handleFullscreen() }>
                    { ads && ( tvSettings.activated && this.state.isOk ) && ( this.state.isAds && somePosts > 0 ) ?
                        <PostTv
                            post={ tvPrayer.somePosts } // posts
                            turningOffAds={ this.turningOffAds }
                            somePosts={ somePosts } // length
                            originalPosts={ tvPrayer.somePosts } // posts
                            tvPrayer={ tvPrayer }
                        /> :
                        <TvUi
                            time={ time }
                            reaload={ reaload }
                            setTvNotificationAndIqama={ this.setTvNotificationAndIqama }
                            setTvNotification={ this.setTvNotification }
                            tvNotification={ this.state.tvNotification }
                            isTvNotification={ this.state.isTvNotification }
                            setIqama={ this.setIqama }
                            setAdhan={ this.setAdhan }
                            turningOffAds={ this.turningOffAds }
                            token={ token }
                            tvPrayer={ tvPrayer }
                            color={ tvSettings.color }
                            langs={ DATA() }
                            switchLangue={ this.switchLangue }
                            tvAdsActivated={ this.state.tvAdsActivated }
                            arabic={ this.state.arabic }
                            adhan={ this.state.adhan }
                            iqama={ this.state.iqama }
                        /> }
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ( {
    admin: state.admin,
} );


export default connect( mapStateToProps, { getPrayerEntityTv, somePostsManagments } )( Television );
